
import { mergeListeners } from '@/util/merge-listeners';
import { BooleanProp, OptionalProp, StringProp } from '@/util/prop-decorators';
import { get } from 'lodash';
import { VNode } from 'vue';
import { Component, Model, Vue } from 'vue-property-decorator';
import VueSelect from 'vue-select';
import OpenIndicator from './icons/chevron-down.svg?vue';
import Deselect from './icons/clear.svg?vue';
import { Input } from './model';

@Component({ inheritAttrs: false })
export default class InputSelect extends Vue implements Input {
  @Model('update')
  private readonly value!: unknown;

  @BooleanProp()
  private readonly error!: boolean;

  @BooleanProp()
  private readonly autofocus!: boolean;

  @OptionalProp()
  private readonly optionLabel?: string | ((value: unknown) => string);

  @StringProp()
  private readonly optionValue?: string;

  @StringProp()
  private readonly dataElementId?: string;

  @OptionalProp(null)
  private readonly unselectedValue!: unknown;

  @StringProp('Wählen…')
  private readonly placeholder?: string;

  @BooleanProp(true)
  private readonly clearable?: boolean;

  public pristine = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public readonly $refs!: { select: any };

  public get normalizedValue(): unknown {
    return this.value;
  }

  public get empty(): boolean {
    return this.value === undefined || this.value === null;
  }

  public open(): void {
    this.$refs.select.open = true;
  }

  public close(): void {
    this.$refs.select.open = false;
  }

  public focus(): void {
    this.$refs.select.searchEl.focus();
  }

  private render(): VNode {
    return this.$createElement(VueSelect, {
      attrs: {
        'data-element-id': this.dataElementId ? this.dataElementId : '',
      },
      props: {
        appendToBody: true,
        getOptionLabel:
          typeof this.optionLabel === 'string'
            ? (option: unknown) => get(option, this.optionLabel as string)
            : this.optionLabel,
        reduce: (option: unknown) => (this.optionValue === undefined ? option : get(option, this.optionValue)),
        ...this.$attrs,
        placeholder: this.placeholder,
        value: this.value,
        clearable: !!this.clearable,
        components: {
          OpenIndicator,
          ...(this.clearable ? { Deselect } : {}),
        },
      },
      class: { 'input-select': true, error: this.error },
      on: mergeListeners(this.$listeners, {
        'search:focus': () => void (this.pristine = false),
        input: (value: unknown) => void this.$emit('update', value ?? this.unselectedValue),
        'hook:mounted': () => void (this.autofocus && this.$refs.select?.searchEl?.focus?.()),
      }),
      ref: 'select',
      scopedSlots: this.$scopedSlots,
    });
  }
}
